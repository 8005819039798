.header {
    text-align: center;
    color: #dc3545;
    font-family: 'Carter One', cursive;
}

.allButtons {
    text-align: center;
    font-family: 'Carter One', cursive;
}

/* .details {
    color:#e1ad01;
} */

.column {
    float: left;
    width: 33%;
    padding: 0 10px;
}

.row {
    margin: 0 -5px;
}

.row:after {
    content: "";
    display: table;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 16px;
    display: inline-block;
    height: fit-content;
    width: fit-content;
    /* background-color: #dc3545; */
    margin-left: 10%;
    outline: none;
}

/* .active{
    background-color: #dc3545;
} */

.button {
    height: 150px;
    width: 300px;
    /* background-color: #dc3545; */
    /* color:#e1ad01; */
    outline: none;
}

.chiliPepper {
    float: right;
    width: 5%;
}

.mongolianBeef {
    position: relative;
    top: -25px;
}

.footerElements {
    padding-top: 3%;
}

.slidingImages {
    width: 50%;
    height: 25%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

footer {
    text-align: center;
}

ul {
    text-align: center;
    padding-right: 25px;
}

/* Responsive */
@media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }

.login-form {
    margin: auto;
    width: 50%;
    padding: 5%;
}